<template>
  <div class="performance-rankings">
    <NormForm
      ref="normForm"
      v-model="queryForm"
      class="norm-form"
      :rules="rules"
      :query-btn-loading="loading"
      @refNode="(dom) => queryFormRef = dom"
      @reset="handleQueryReset"
      @query="handleQueryForm"
    >
      <el-form-item label="规则名称" prop="performanceRuleId">
        <el-select v-model="queryForm.performanceRuleId" placeholder="请选择规则名称" clearable filterable @change="handleRuleSelectChange">
          <el-option
            v-for="item in ruleOptions"
            :key="item.performanceRuleId"
            :label="item.performanceRuleName"
            :value="item.performanceRuleId"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="!updateCycle" label="日期范围选择" prop="date">
        <el-link disabled>请先选择规则名称</el-link>
      </el-form-item>
      <el-form-item v-if="updateCycle === 1" label="日期范围选择" prop="weekDate">
        <el-date-picker
          key="startWeek"
          v-model="queryForm.weekDate[0]"
          :picker-options="startWeekPickerOptions"
          type="week"
          format="yyyy 第 WW 周"
          placeholder="选择开始时间"
          value-format="yyyy-MM-dd"
        />
        <small class="mx-2">~</small>
        <el-date-picker
          key="endWeek"
          v-model="queryForm.weekDate[1]"
          :picker-options="endWeekPickerOptions"
          type="week"
          format="yyyy 第 WW 周"
          placeholder="选择结束时间"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item v-if="updateCycle === 2" label="日期范围选择" prop="monthDate">
        <el-date-picker
          key="monthDate"
          v-model="queryForm.monthDate"
          type="monthrange"
          range-separator="~"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item v-if="updateCycle === 3" label="日期范围选择" prop="quarterDate">
        <div class="quarter-select">
          <VueQuarterSelect
            key="startQuarterDate"
            v-model="queryForm.quarterDate[0]"
            placeholder="开始季度"
            :max-date="quarterMaxDate"
            size="medium"
          />
          <small class="mx-2">~</small>
          <VueQuarterSelect
            key="endQuarterDate"
            v-model="queryForm.quarterDate[1]"
            placeholder="结束季度"
            :min-date="quarterMinDate"
            size="medium"
          />
        </div>
      </el-form-item>
      <el-form-item v-show="dateRange.beginDate && dateRange.endDate" label="日期范围展示">
        <div>{{ dateRange.beginDate }} ~ {{ dateRange.endDate }}</div>
      </el-form-item>
    </NormForm>
    <vxe-toolbar>
      <template v-slot:buttons>
        <el-button type="primary" @click="handleExport">导出</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="xTable"
      v-loading="loading"
      border
      :show-overflow="false"
      class="editTable"
      max-height="550px"
      align="center"
      :scroll-y="{enabled: false}"
      :span-method="mergeRowMethod"
      :data="tableData"
    >
      <vxe-table-column field="employeeName" title="人员" min-width="100" />
      <vxe-table-column field="performanceScore" title="得分" min-width="100" />
      <vxe-table-column field="rank" title="排名" min-width="100" />
    </vxe-table>
  </div>
</template>

<script>
import NormForm from '@/components/NormForm'
import dayjs from 'dayjs'
import {
  getPerformanceRuleByPage,
  getScoreRank,
  getCycleByDateRange
} from '@/api/performance'
import VueQuarterSelect from '@3scarecrow/vue-quarter-select'
import XEUtils from 'xe-utils'
import { omit } from 'lodash'
import { ObjectToURL } from '@/utils'
export default {
  components: {
    NormForm,
    VueQuarterSelect
  },
  data() {
    const weekDateValidate = (rule, value, callback) => {
      const [beginDate = '', endDate = ''] = value || []
      if (!beginDate || !endDate) {
        callback(new Error('请选择日期'))
      }
      callback()
    }
    const quarterDateValidate = (rule, value, callback) => {
      const [startQuarterDate = [], endQuarterDate = []] = value || [[], []]
      const [beginDate] = startQuarterDate || []
      const [, endDate] = endQuarterDate || []
      if (!beginDate || !endDate) {
        callback(new Error('请选择日期'))
      }
      callback()
    }
    return {
      queryFormRef: {},
      queryForm: {
        performanceRuleId: '',
        date: [],
        weekDate: [],
        monthDate: [],
        quarterDate: [[], []]
      },
      rules: {
        performanceRuleId: [{ required: true, message: '请选择规则名称', trigger: ['change', 'blur'] }],
        date: [{ required: true, message: '请选择日期', trigger: ['change', 'blur'] }],
        weekDate: [{ required: true, validator: weekDateValidate, trigger: ['change', 'blur'] }],
        monthDate: [{ required: true, message: '请选择日期', trigger: ['change', 'blur'] }],
        quarterDate: [{ required: true, validator: quarterDateValidate, trigger: ['change', 'blur'] }]
      },
      loading: false,
      updateCycle: 0,
      tableData: [],
      ruleOptions: [],
      fields: ['rank'],
      pickerOptions: {},
      startWeekPickerOptions: this.handleStartWeekPickerOptions(),
      endWeekPickerOptions: this.handleEndWeekPickerOptions(),
      dateRange: {
        beginDate: '',
        endDate: ''
      }
    }
  },
  computed: {
    getScoreRankParams() {
      let beginDate = ''
      let endDate = ''
      // week
      if (this.updateCycle === 1) {
        [beginDate, endDate] = this.queryForm.weekDate || []
        beginDate = beginDate ? dayjs(beginDate).startOf('week').add(1, 'day').format('YYYY-MM-DD') : null
        endDate = endDate ? dayjs(endDate).endOf('week').add(1, 'day').format('YYYY-MM-DD') : null
      // month
      } else if (this.updateCycle === 2) {
        [beginDate, endDate] = this.queryForm.monthDate || []
        endDate = endDate ? dayjs(endDate).endOf('month').format('YYYY-MM-DD') : null
      // quarter
      } else if (this.updateCycle === 3) {
        beginDate = this.queryForm.quarterDate[0]?.[0] || ''
        endDate = this.queryForm.quarterDate[1]?.[1] || ''
      }
      return {
        performanceRuleId: this.queryForm.performanceRuleId,
        beginDate,
        endDate
      }
    },
    quarterMaxDate() {
      return this.queryForm?.quarterDate[1][1]
    },
    quarterMinDate() {
      return this.queryForm?.quarterDate[0][0]
    }
  },
  watch: {
    'queryForm.performanceRuleId'(id) {
      this.updateCycle = this.ruleOptions
        .find(item => id === item.performanceRuleId)
        ?.updateCycle
      Object.assign(this.queryForm, omit(this.$options.data.call(this).queryForm, ['performanceRuleId', 'queryFormRef']))
      this.$nextTick(() => {
        this.queryFormRef.clearValidate()
      })
    }
  },
  mounted() {
    this._getPerformanceRuleByPage()
  },
  methods: {
    handleQueryForm() {
      this._getScoreRank()
    },
    handleRuleSelectChange() {
      this.dateRange = this.$options.data.call(this).dateRange
    },
    handleQueryReset() {
      Object.assign(this.queryForm, omit(this.$options.data.call(this).queryForm, ['queryFormRef']))
      this.dateRange = this.$options.data.call(this).dateRange
      this.$nextTick(() => {
        this.queryFormRef.clearValidate()
      })
    },
    async handleExport() {
      this.$refs['normForm'].getValidate().then(() => {
        // await exportScoreRank(this.getScoreRankParams)
        window.open(
          process.env.VUE_APP_OMS_API + 'oms/performance/exportScoreRank' + ObjectToURL({ ...this.getScoreRankParams })
          , '_blank')
      })
    },
    async _getPerformanceRuleByPage() {
      const { datas: { records }} = await getPerformanceRuleByPage({ size: 9999 })
      this.ruleOptions = records.filter(item => item.performanceRuleStatus === 1 || item.performanceRuleStatus === 0)
    },
    async _getScoreRank() {
      try {
        this.loading = true
        this.dateRange = this.$options.data.call(this).dateRange
        const { datas } = await getScoreRank(this.getScoreRankParams)
        this.tableData = datas
          .sort((a, b) => b.performanceScore - a.performanceScore)
          .reduce((acc, cur) => {
            if (acc.length === 0) {
              acc.push({ ...cur, rank: 1 })
            } else {
              const { length, last = length - 1 } = acc
              const current = acc[last]
              if (current.performanceScore === cur.performanceScore) {
                acc.push({ ...cur, rank: current.rank })
              } else {
                acc.push({ ...cur, rank: current.rank + 1 })
              }
            }
            return acc
          }, [])
        const { datas: data } = await getCycleByDateRange(this.getScoreRankParams)
        this.dateRange = data
      } finally {
        this.loading = false
      }
    },
    handleStartWeekPickerOptions() {
      const context = this
      return {
        firstDayOfWeek: 1,
        disabledDate(time) {
          let endDate = context.queryForm.weekDate?.[1]
          endDate = endDate ? dayjs(endDate).endOf('week').add(1, 'day').format('YYYY-MM-DD') : null
          return endDate ? time > dayjs(endDate) : false
        }
      }
    },
    handleEndWeekPickerOptions() {
      const context = this
      return {
        firstDayOfWeek: 1,
        disabledDate(time) {
          let startDate = context.queryForm.weekDate?.[0]
          startDate = startDate ? dayjs(startDate).startOf('week').add(1, 'day').format('YYYY-MM-DD') : null
          return startDate ? time < dayjs(startDate) : false
        }
      }
    },
    mergeRowMethod({ row, _rowIndex, column, visibleData }) {
      const fields = this.fields
      const cellValue = XEUtils.get(row, column.property)
      if (cellValue && fields.includes(column.property)) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && XEUtils.get(prevRow, column.property) === cellValue) {
          return { rowspan: 0, colspan: 0 }
        } else {
          let countRowspan = 1
          while (nextRow && XEUtils.get(nextRow, column.property) === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.performance-rankings /deep/ {
  .norm-form .quarter-select {
    width: 200px;
    display: flex;
    .sw__wrapper {
      width: 200px;
    }
    .sw__trigger {
      overflow: hidden;
    }
  }
}
</style>
